@tailwind base;
@tailwind components;
@tailwind utilities;


$techblue: #005379;



@import "header.scss";




* {
    font-family: 'Roboto', sans-serif;
}

body{
    background-color: #eee;
}

span,
label,
p,
b {
    color: #222222;
}
h1, h2, h3, h4, h5, h6 {
    color: $techblue;
}
label {
    margin-bottom: .6rem;
    font-size: 1rem;
    font-weight: 700;
    color: #222222;
    text-transform: uppercase;
}

span {
    font-size: 1rem;
}


small.field-description-text {
    margin-top: 6px;
    display: block;
    font-size: 14px;
    font-style: italic;
}


.mainContent {
    padding-top: 160px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    gap: 60px;

    div {
        width: 100%;
    }
}


.flex-col {
    display: flex;
    flex-direction: column;

    &.gap-20 {
        gap: 20px;
    }

    &.gap-40 {
        gap: 40px;
    }
}


.testSending_btn {
    width: auto;
    margin: 4rem auto;
    display: block;
    padding: 10px 40px;
    background-color: $techblue;
    border-radius: 12px;
    color: #FFF;
    font-size: 1.5rem;
    font-weight: bold;
}

.autocomplete {
    position: relative;
}

.suggestions {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        padding: 10px;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}