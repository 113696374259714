.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 1rem;
    width: 30%;

    .checkbox-card-label {
        margin-left: 10px;
        margin-bottom: 0;
    }
    
    input {
        height: 20px;
        width: 20px;
    }
    label {
        margin-left: 1rem;
        font-size: 18px;
        text-transform: none;
    }
}

.checkboxSubContext {
    font-size: 16px;
    width: 70%;
    font-style: italic;
}