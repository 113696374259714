.errorsPage {
    width: 80%;
    margin: 4rem auto;

    h1 {
        margin-bottom: 2rem;
        color: #005379;
        font-size: 2rem;
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        border: 2px solid #8a8c8db7;
        border-radius: 12px;
        overflow: hidden;

        thead {
            width: 100%;

            
            
            tr {
                width: 100%;
                text-align: left;

                th:first-child {
                    // border-top-left-radius: 9px; // Top left radius
                }
                th:last-child {
                    // border-top-right-radius: 9px; // Top right radius
                }
                th {
                    background-color: #8a8c8db7;
                }

                th {
                    padding: 10px 40px;
                }
            }
        }

        tbody {
            padding: 0 40px;
            tr {
                height: 60px;

                td:first-child {
                    border-bottom-left-radius: 12px; // Bottom left radius on the last row
                }
                td:last-child {
                    border-bottom-right-radius: 12px; // Bottom right radius on the last row
                }
                td {
                    padding: 0 40px;

                    select {
                        background-color: transparent;
                        cursor: pointer;
                    }

                    svg {
                        cursor: pointer;
                    }

                    &.role {
                        display: inline-block;
                    }

                }
                &:last-child td:first-child {
                    border-bottom-left-radius: 12px;
                }
                &:last-child td:last-child {
                    border-bottom-right-radius: 12px;
                }
            }
        }
    }
}