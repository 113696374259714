.sectionFour {
    margin-top: 6rem;

    .imageVideoAd {

        .content {
            h2 {
                text-align: center;
                font-size: 2rem;
                font-weight: 600;
            }
            div.border {
                width: 14%;
                margin: 10px auto 10px auto;
                border: 2px solid #F16629;
            }
        }

        .checkboxes {
            width: 40%;
            margin: 2rem auto 2rem auto;
        }

        .vidExamples {
            width: 60%;
            margin: 0 auto;
            justify-content: space-evenly;
            gap: 40px;

            small {
                border: 2px dotted #F16629;
                padding: 14px;
                border-radius: 8px;
                font-size: .8rem;
                font-weight: 600;
                color: #5F5F5F;
                width: 100%;

                a {
                    color: #F16629;
                    text-decoration: underline;
                }
            }
        }

        .stillSlides {
            width: 60%;
            margin: 0 auto;

            h3 {
                text-align: center;
                font-size: 1rem;
                width: 85%;
                margin: 2rem auto 0 auto;
                color: #000;
                font-weight: 600;
            }

            input {
                &::placeholder {
                    color: #5F5F5F;
                    font-weight: 600;
                }
            }
        }

        .last-poster {
            width: 60%;
            margin: 3rem auto 0 auto;

            label {
                text-align: center;
                width: 100%;
                display: block;
            }

            textarea {
                &::placeholder {
                    color: #5F5F5F;
                    font-weight: 600;
                }
            }
        }
    }
}