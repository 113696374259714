.sectionFive {
    width: 60%;
    margin: 0 auto;

    .fileUpload {

        .headline {
            text-align: center;
            font-size: 2rem;
            font-weight: 700;
            color: #005379;
            margin: 0;
        }

        .border {
            width: 20%;
            margin: 1rem auto 1rem auto;
            border: 2px solid #F16629;
        }

        small {
            text-align: center;
            color: #5F5F5F;
            margin: 0 0 2rem 0;
        }

        .file-upload-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px dashed #F16629;
            padding: 20px 0;
            border-radius: 50px;
            cursor: pointer;
            width: 60%;
            margin: 0 auto;

            &__content {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                label {
                    margin: 0;
                    text-transform: none;
                }
                svg {
                    fill: #F16629;
                }
                
                .file-upload-btn {
                    background-color: #007bff;
                    color: #ffffff;
                    padding: 10px 20px;
                    border-radius: 5px;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
            }

            .fileUploadName {
                color: #333;
                font-size: 16px;
                line-height: 1.5;
                width: 80%;
                margin-top: 10px;
                text-overflow: ellipsis;
                white-space: normal;
                overflow-wrap: break-word;
                word-break: break-all;
            }

            .selected-files-list {
                display: flex;
                flex-direction: column;
                gap: 12px;
                
                div {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    
                    button {
                        color: #FFF;
                        background-color: red;
                        padding: 2px 8px;
                        border-radius: 12px;
                        cursor: pointer;
                        font-size: 14px;
                    }
                }
            }
        }
          
    }
}