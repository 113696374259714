.order-packages {
    // margin-bottom: 5rem;
    margin-top: 3rem;

    .sectionTitle {
        text-align: center;
        color: #005379;
        font-size: 2rem;
        font-weight: 600;
    }

    .border {
        width: 20%;
        margin: 10px auto 10px auto;
        border: 2px solid #F16629;
    }

    .titleDesc {
        margin-bottom: 2rem;
        p {
            text-align: center;
            color: #616161;
            font-weight: 600;
        }
    }


    &__container {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;

        .radio-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // margin-right: 10px;
            flex: 1;
            width: calc(100% / 3 - 20px);
            background-color: #f5f5f5;
            border-radius: .2rem;
            border: 1px solid #ccc;
            padding: 1.5rem;
            box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

            &:first-of-type {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            input {
                display: none;
            }
    
            label.custom-radio {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                cursor: pointer;
    
                .content-box {
                    flex-grow: 1;

                    .title {
                        font-size: 2rem;
                        text-align: center;
                    }

                    .border {
                        width: 20%;
                        margin: 10px auto 10px auto;
                        border: 2px solid #F16629;
                    }

                    .description {
                        font-size: 1.2rem;
                        text-align: center;
                        color: #616161;
                        text-transform: none;

                    }
                }

                .order-status {
                    margin-top: 3rem;
                    background-color: #005379;
                    border-radius: .3rem;
                    padding: .75rem;
                    text-align: center;
                    font-weight: bold;
                    font-size: 1em;
                    color: white;

                    &.chosen {
                        background-color: #F16629;
                    }
                }
            }
        }
    }
}

.orderPackages {
    margin: 3rem 0;

    .contactPackage {
        background-color: #EEEEEE;
        padding: 2rem;

        .header {
            h2,
            p {
                text-align: center;
            }
            h2, span {
                font-size: 2rem;
                color: #000;
                font-weight: 600;
            }
            span {
                color: #F16629;
            }
            p {
                color: #616161;
                font-weight: 600;
            }
        }

        .inputs {
            margin-top: 2rem;
        }
    }
}

.addPackages {
    padding: 3rem 0;

    h3 {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 4rem;
    }

    div.grid {
        width: 80%;
        margin: 0 auto;

        label {
            text-transform: none;
            margin: 0;
        }
    }
}



.sub-packages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        width: calc(100% / 2);
    }
}