.sectionOne {


    .flex-row {
        gap: 40px;

        .norgesgruppenCheck {
            width: 35%;
        }
        .checkboxSubContext {
            width: 75%;
            padding: 10px 80px 10px 10px;
            border: 2px dotted #F16629;
            border-radius: 8px;

            small {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: relative;

                svg {
                    position: absolute;
                    right: -70px;
                    top: 50%;
                    transform: translateY(-50%);
                    fill: #F16629;
                }
            }
        }

        .checkbox-container {

            label {
                span {
                    font-weight: bold;
                    b {
                        color: #F16629;
                    }
                }
            }
        }
    }

    .stillBtn {
        margin-top: 40px;
    }
}