.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        
        span {
            margin-left: 8px;
        }
    }

    input {
        border: 1px solid #ccc;
        width: 100%;
        padding: 1rem;
        border-radius: .3rem;
        &:focus{
            outline: none;
            border: 1px solid #fff;
            box-shadow: 0 0 6px rgba(0,83,121,.5);
        }
    }
}