.btn-containers {
    margin-bottom: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    h2{
        font-size: 1.5rem;
    }

    button {
        background-color: #005379;
        padding: 16px 40px;
        border-radius: 8px;
        color: #FFF;
        font-weight: bold;

        &:hover {
            transition: all .3s;
            font-weight: 600;
            background-color: #F16629;
        }

        &.selected {
            background-color: #F16629;
        }
    }
}