.profile {
    width: 90%;
    margin: 0 auto;

    h1 {
        font-size: 2rem;
        font-weight: bold;
    }

    .profileOptions {
        width: 50%;
        margin: 2rem auto 0;
        border-top: 2px solid #d2d2d2;

        h3 {
            text-align: center;
            width: 50%;
            margin: 1rem auto;
        }

        .profileBtns {
            display: flex;
            flex-direction: row;
            gap: 40px;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;

            button {
                background-color: #005379;
                padding: 8px 16px;
                color: #FFF;
                font-weight: bold;
                transition: .3s ease-in-out;

                &:hover {
                    background-color: #d2d2d2;
                    color: #005379;
                    border-radius: 8px;
                    transition: .3s ease-in-out;
                }
            }
        }
    }

    .profileRenders {

        h2 {
            text-align: center;
            font-size: 1.4rem;
            font-weight: bold;
        }

        .userInfo {
            width: 60%;
            margin: 4rem auto;

            form {
                width: 70%;
                margin: 2rem auto 0;

                .column {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    width: 100%;
                }
                .row {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                }

                input,
                select {
                    height: 40px;
                    padding: 0 10px;
                }
            }

            .profileMsg {
                text-align: center;
                background-color: #F16629;
                width: 50%;
                margin: 0 auto;
                padding: 20px 40px;
                font-size: 20px;
                color: #FFF;
                border-radius: 8px;
            }

            .editButtons {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 40px;
                margin-top: 20px;

                button {
                    padding: 8px 20px;
                    color: #FFF;
                    font-weight: bold;
                    transition: .3s ease-in-out;
                }

                button.abortBtn {
                    background-color: red;
                }

                button.confBtn,
                button.changeBtn {
                    background-color: #005379;
                }
            }
        }

        .profileChangePassword {
            width: 60%;
            margin: 4rem auto;

            form {
                width: 50%;
                margin: 2rem auto 0;

                div {
                    width: 100%;
                    margin-bottom: 20px;
                    
                    input {
                        width: 100%;
                        height: 40px;
                        padding: 0 10px;
                    }
                }
            }

            button {
                background-color:#005379;
                padding: 8px 16px;
                color: #FFF;
                font-weight: bold;
                transition: .3s ease-in-out;
                margin: 0 auto;
                display: block;
            }

            .passwordMsg {
                width: 50%;
                margin: 0 auto;
                text-align: center;
                margin-top: 2rem;
                background-color:#F16629;
                padding: 20px 40px;
                font-size: 20px;
                color: #FFF;
                border-radius: 8px;
            }
        }

        .profileOrders {
            width: 60%;
            margin: 4rem auto 0;

            table {
                width: 100%;
                margin-top: 4rem;
    
                thead {
    
                    tr {
                        margin-top: 12px;
                        height: 50px;
                        background-color: #005379;
    
                        th {
                            text-align: left;
                            color: #FFF;
                            padding-left: 20px;
                        }
                    }
                }
    
                tbody {
    
                    tr {
                        td {
                            padding-left: 20px;
                            height: 40px;
    
                            .row {
                                display: flex;
                                flex-direction: row;
    
                                svg {
                                    fill: red;
                                }
    
                                a {
                                    margin-left: 10px;
                                    color: #005379;;
                                }
                            }
                        }
    
                        &:nth-child(even) {
                            background-color: #f2f2f2;
                        }
                        &:nth-child(odd) {
                            background-color: #d2d2d2;
                        }
                    }
                }
            }
        }
    }
}