.setPasswordFormContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .setPasswordForm {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
        background-color: #005379;
        padding: 40px;

        h2 {
            text-align: center;
            color: #FFF;
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 20px;

            > div {
                display: flex;
                flex-direction: column;

                label {
                    color: #FFF;
                }

                input {
                    height: 30px;
                    padding: 4px 8px;
                    border-radius: 4px;
                }
            }

            input[type=submit] {
                background-color: #FFF;
                color: #005379;
                font-weight: bold;
                font-size: 1.2rem;
                margin-top: 1.5rem;
                cursor: pointer;
            }
        }

        p {
            text-align: center;
            margin-top: 2rem;
            color: #FFF;
            font-weight: bold;
            letter-spacing: 1.3px;
            font-size: 22px;
            text-decoration: underline;
        }

        a {
            text-align: center;
            display: block;
            font-size: 2rem;
            color: #FFF;
            font-weight: bold;
            background-color: #FFF;
            color: #005379;
            padding: 4px 0;
            transition: .4s ease-in-out;
            border-radius: 0;
            width: 60%;
            margin: 0 auto;

            &:hover {
                border-radius: 8px;
                transition: .4s ease-in-out;
                width: 70%;
            }
        }
    }
}