.textarea-container {
    position: relative;
    display: inline-block;

    label {
        
        span {
            margin-left: 8px;
        }
    }

    textarea {
        border: 1px solid #ccc;
        width: 100%;
        padding: 1rem;
        border-radius: .3rem;
        &:focus{
            outline: none;
            border: 1px solid #fff;
            box-shadow: 0 0 6px rgba(0,83,121,.5);
        }
    }


    .char-counter {
        position: absolute;
        bottom: 5px;
        left: 5px;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 2px 4px;
        font-size: 0.75rem;
        z-index: 2;
    }
}