.sectionSix {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
    gap: 40px;

    .flex-row {
        gap: 60px;
    }

    .geo {
        
        .geoAge {
            width: 20%;
        }
    }

    .checkbox-container {
        width: 100%;
    }
}