.main-header {
    background-color: $techblue;
    margin-bottom: 4rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 555;
    height: 100px;


    .header-content {
        width: 70%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        * {
            color: #FFF;
        }

        .ac-logo {
            img {
                width: 60px;
            }
        }

        .menuItems {
            width: 20%;
            margin: 0 2rem;
            
            ul {
                display: flex;
                flex-direction: row;
                gap: 20px;

                li {
                    a {
                        font-size: 1.2rem;
                        font-weight: bold;
                    }
                }
            }
        }

        .userIcon {
            ul {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                li {
                    a, button {
                        font-size: 1.2rem !important;
                    }
                }
            }
        }

        .alogo-section {
            display: flex;
            flex-direction: row;
            position: relative;
            align-items: center;

            .contact {
                width: 300px;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: center;


                h2 {
                    font-weight: bold;
                }

                div {
                    display: flex;
                    flex-direction: row;
                    justify-content: end;
                    align-items: center;

                    svg {
                        margin-right: 8px;
                    }
                }
            }

            .border {
                border: 2px solid #F16629;
                margin: 0 14px;
                height: 50px;
            }

            img {
                height: 60px;
                padding: 0 0 0 10px;
            }
        }

    }

}