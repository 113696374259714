.regOuter {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    .registerForm {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #005379;
        padding: 40px 60px;
        width: 30%;
        border-radius: 12px;
    
        h2 {
            color: #FFF;
            font-weight: bold;
            font-size: 2rem;
            text-align: center;
            margin-bottom: 2rem;
        }
    
        .registerContainer {
            form {
                display: flex;
                flex-direction: column;
                gap: 20px;
    
                div {
                    display: flex;
                    flex-direction: column;
    
                    input, select {
                        width: 100%;
                        height: 30px;
                        border-radius: 4px;
                        padding: 4px 6px;
                    }
    
                    label {
                        color: #FFF;
                    }
                }
    
                .regFirstLast {
                    flex-direction: row;
                    gap: 10px;
                }
            }

            p {
                color: #FFF;
                margin-top: 2rem;
                font-size: 1.2rem;
                text-align: center;
                text-decoration: underline;
            }
        }

        button[type=submit] {
            background-color: #FFF;
            color: #005379;
            font-weight: bold;
            border-radius: 6px;
            margin-top: 1rem;
            font-size: 1.2rem;
            padding: 8px 0;
            cursor: pointer;

            &:hover {
                background-color: #228347;
                color: #FFF;
            }
        }
    }
}