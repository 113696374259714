.sectionThree {
    margin-top: 6rem;
    
    .landSok {
        width: 45%;
        gap: 2rem;

        span {
            margin: 0;
            b {
                color: #F16629;
            }
        }
    }

    .feed {
        height: auto;

        textarea {
            height: calc(100% - 24px);
        }

        small {
            width: 80%;
            border: 2px dotted #F16629;
            border-radius: 8px;
            padding: 14px 40px 14px 14px;
            font-size: .8rem;
            position: relative;
            margin-top: 24px;
            margin-bottom: -24px;
            display: block;
            height: calc(100% - 24px);

            svg {
                position: absolute;
                right: 12px;
                bottom: 12px;
                fill: #F16629;
            }
        }
    }
}