.adminPanel {
    display: flex;
    height: 100vh;
    overflow: hidden;

    &__content {
        flex-grow: 1;
        overflow-y: auto;
        background-color: #d6d2d2bd;
    }
}