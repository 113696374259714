.adminMenu {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #005379;
    width: 240px;
    flex-shrink: 0;

    &__container {
        padding: 20px;

        .logo {
            height: 100px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            padding: 20px;
        }

        nav {
            padding-top: 4rem;

            ul {

                li {
                    cursor: pointer;
                    color: #FFF;
                    font-size: 1.2rem;
                }
            }
        }
    }
}