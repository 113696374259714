.sectionSeven {
    width: 80%;
    margin: 6rem auto 0 auto;

    .header {
        h2, small {
            text-align: center;
        }

        h2 {
            font-size: 2rem;
            margin: 0;
            font-weight: 600;
        }

        .border {
            width: 14%;
            margin: 10px auto 10px auto;
            border: 2px solid #F16629;
        }

        small {
            color: #5F5F5F;
            width: 45%;
            margin: 0 auto 2rem auto;
            font-weight: 600;
        }
    }

    .flex-row {
        gap: 20px;
        align-items: stretch;

        .flex-col {
            width: 100%;
            gap: 20px;
            justify-content: space-between;

            small.field-description-text {
                border: 2px dotted #F16629;
                padding: 12px 12px 40px 12px;
                min-height: 100px;
                flex-grow: 1;
                display: flex;
                position: relative;
                border-radius: 8px;

                svg {
                    fill: #F16629;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                }
            }
        }
    }

    input[type=submit] {
        width: 30%;
        margin: 4rem auto 4rem auto;
        display: block;
        background-color: #005379;
        padding: 12px;
        border-radius: 8px;
        color: #FFF;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        cursor: pointer;
        box-shadow: 2px 4px 5px 2px rgba(0, 0, 0, 0.7);
        transition: .2s ease-in-out;

        &:hover {
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.7);
            transform: scale(0.99);
            transition: .2s ease-in-out;
        }
    }
}