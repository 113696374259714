.review {
    width: 60%;
    margin: 0 auto;


    .header {
        * {
            text-align: center;
            display: block;
        }

        h1 {
            font-size: 2rem;
            font-weight: bold;
            margin: 0;
            padding: 0;
        }

        .border {
            width: 40%;
            margin: 1rem auto;
            height: 6px;
            background-color: #F16629;
        }

        small {
            width: 60%;
            margin: 0 auto;
            font-size: 1rem;
            color: #6A6A6A;
            font-weight: bold;
        }
    }

    form {
        .star-containers {
            display: flex;
            flex-direction: row;
            gap: 40px;
            margin: 6rem auto 0;
            width: 80%;
    
            &__cont-1,
            &__cont-2 {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                justify-content: flex-start;
                padding: 2rem;
                border: 2px dotted #F16629;
                border-radius: 8px;
    
                h2 {
                    font-size: 1rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    color: #000;
                }
    
    
                .star-items {
                    display: flex;
                    flex-direction: row;
    
                    .star {
                        color: #ddd;
                        cursor: pointer;
            
                        &:hover,
                        &.filled {
                            color: #F16629;
                        }
                    }
                }
            }
        }

        .text-review {
            width: 50%;
            margin: 3rem auto 0;

            h2 {
                text-align: center;
                color: #000;
                font-weight: bold;
                font-size: 1.2rem;
                margin-bottom: 1rem;
            }

            textarea {
                display: block;
                width: 100%;
                height: 200px;
                border-radius: 8px;
                padding: 12px;
            }
        }

        .review-btns {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 40%;
            margin: 0 auto;

            button {
                margin: 3rem auto;
                padding: 16px 40px;
                border-radius: 8px;
                display: block;
                color: #FFF;
                font-weight: bold;
                font-size: 1.4rem;
                width: calc(100% / 2);
            }

            .submit-review {
                background-color: #005379;
            }
            .exit-review {
                background-color: red;
            }


        }
    }
}