.orderData {
    width: 70%;
    margin: 0 auto;

    h1 {
        font-size: 2rem;
        color: #005379;
        margin-bottom: 2rem;
    }

    input {
        margin: 0 0 20px 0;
        height: 40px;
        padding: 0 20px;
        font-size: 16px;
        box-sizing: border-box;
    }

    .ordersList {

        table {
            width: 100%;

            thead {

                tr {
                    margin-top: 12px;
                    height: 50px;
                    background-color: #005379;

                    th {
                        text-align: left;
                        color: #FFF;
                        padding-left: 20px;
                    }
                }
            }

            tbody {

                tr {
                    td {
                        padding-left: 20px;
                        height: 40px;

                        .row {
                            display: flex;
                            flex-direction: row;

                            svg {
                                fill: red;
                            }

                            a {
                                margin-left: 10px;
                                color: #005379;
                            }
                        }
                    }

                    &:nth-child(even) {
                        background-color: #f2f2f2;
                    }
                    &:nth-child(odd) {
                        background-color: #d2d2d2;
                    }
                }
            }
        }
    }
}