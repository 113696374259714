.modal {
	display: none;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

	&.show {
		display: block;
	}

	.modal-content {
		background-color: #fefefe;
		margin: 15% auto;
		padding: 20px;
		width: 80%;
		max-width: 500px;
        border-radius: 12px;
	}

	.close {
		color: #aaa;
		float: right;
		font-size: 28px;
		font-weight: bold;

		&:hover,
		&:focus {
			color: black;
			text-decoration: none;
			cursor: pointer;
		}
	}

    h2 {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    button {
        background-color: #005379;
        padding: 8px 12px;
        border-radius: 8px;
        margin-top: 12px;
        color: #FFF;
    }
}