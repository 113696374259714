.loginForm {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;


    .formContainer {
        background-color: #005379;
        padding: 20px 40px;
        border-radius: 20px;
        width: 20%;

        h2 {
            color: #FFF;
            font-size: 2rem;
            text-align: center;
            margin-bottom: 2rem;
        }

        .form {
            form {
                display: flex;
                flex-direction: column;
                gap: 30px;
                width: 100%;

                div {
                    input {
                        height: 30px;
                        padding: 8px;
                        border-radius: 6px;
                        width: 100%;
                    }

                    &.passField {
                        display: flex;
                        align-items: center;
                        position: relative;

                        input {
                            flex-grow: 1;
                        }

                        button {
                            background-color: #228347;
                            color: #FFF;
                            border: none;
                            cursor: pointer;
                            position: absolute;
                            right: -1px;
                            top: 0;
                            height: 100%;
                            border-radius: 0 6px 6px 0;
                            padding: 0 15px;

                            &:hover, &:focus {
                                background-color: #228347;
                                outline: none;
                            }
                        }
                    }
                }

                input[type="submit"] {
                    background-color: #FFF;
                    color: #005962;
                    border: none;
                    padding: 10px;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: 0.3s;
                    font-weight: bold;
                    font-size: 1.2rem;

                    &:hover {
                        background-color: #228347;
                        color: #FFF;
                    }
                }

                .forgotPass_btn {
                    background-color: red;
                    color: #FFF;
                    padding: 4px 0;
                    border-radius: 8px;
                    cursor: pointer;
                    transition: .3s ease;

                    &:hover {
                        transform: scale(1.1);
                        transition: .3s ease;
                    }
                }
            }

            p {
                margin-top: 1rem;
                text-align: center;
                color: #FFF;
                font-weight: bold;
                letter-spacing: 1.3px;
                font-size: 22px;
            }
        }
    }

}